<template>
  <main>
    <Login class="absolute w-screen h-screen bg-cover" />
    <div class="flex min-h-screen">
      <div class="flex-grow flex flex-col relative mt-40 px-40 bg-white">
        <div
          v-if="hasHeaderSlot"
          class="w-full px-40 pt-25 flex items-center justify-between mb-20"
        >
          <slot name="header" />
        </div>
        <div v-if="showLanguage" class="flex justify-end">
          <LanguageSelect class="relative" floating />
        </div>
        <div
          class="flex-grow items-center justify-center"
          :class="{ 'flex flex-col': !topAlign }"
        >
          <div class="w-full mx-auto">
            <slot />
            <div v-if="showContact" class="mt-35 text-center">
              <button
                class="inline-block text-gray-400 font-semibold"
                @click="supportModalOpen = true"
              >
                {{ $t('common.contact_support') }}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal :active="supportModalOpen" closeOnBackDrop @close="closeModal">
        <ContactSupportModal @close="closeModal" />
      </Modal>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactSupportModal from '@components/common/ContactSupportModal'
import Modal from '@components/common/Modal'
import Footer from '@components/common/Footer'
import LanguageSelect from '@components/common/LanguageSelect'
import { Login } from '@assets/svgs/index'

export default {
  components: { ContactSupportModal, Modal, Footer, Login, LanguageSelect },
  props: {
    showContact: {
      type: Boolean,
      default: true,
    },
    topAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportModalOpen: false,
    }
  },
  computed: {
    ...mapGetters('client', ['showLanguage']),
    hasHeaderSlot() {
      return this.$slots.header
    },
  },
  methods: {
    closeModal() {
      this.supportModalOpen = false
    },
  },
}
</script>
