<template>
  <RegistrationLayout topAlign :showContact="false">
    <div class="md:w-700 md:mx-auto">
      <img
        v-for="(logo, idx) in clientLogos"
        :key="idx"
        class="mx-auto w-200"
        :class="{ 'mb-25': idx !== clientLogos.length - 1 }"
        :src="logo"
      />
      <div class="mt-20 border rounded-lg border-grey-300">
        <div v-if="hasError" class="px-40 py-20">
          <h6 class="mb-10">{{ $t('subscriptions.invalid_link') }}</h6>
          <section>
            <p class="mb-10 text-grey-600 text-sm">
              {{ $t('subscriptions.invalid_body_1') }}
            </p>
            <p class="text-grey-600 text-sm">
              {{ $t('subscriptions.invalid_body_2') }}
            </p>
          </section>
          <HMButton
            size="x-small"
            class="inline-block text-gray-400 font-semibold mt-25"
            @click="supportModalOpen = true"
          >
            {{ $t('common.contact_support') }}
          </HMButton>
        </div>
        <div v-else>
          <div class="px-40 py-20">
            <h6 class="pt-30 pb-10 ">{{ $t('subscriptions.title') }}</h6>
            <p class="text-grey-600 text-sm pb-10">
              {{ $t('notification_settings.email_disclaimer') }}
            </p>
          </div>
          <hr />
          <div v-if="!commentSubmitted" class="px-40 py-20">
            <h6 class="mb-10">{{ $t('subscriptions.comment.title') }}</h6>
            <section>
              <p class="mb-10 text-grey-600 text-sm">
                {{ $t('subscriptions.comment.body') }}
              </p>
              <textarea
                v-model="comment"
                class="block w-full p-2 mb-10 border rounded border-grey-300 h-80"
              />
              <HMButton
                size="x-small"
                rounded="full"
                class="mt-25"
                :disabled="!comment"
                @click="onSubmitComment"
              >
                {{ $t('common.submit') }}
              </HMButton>
            </section>
          </div>
          <div v-else class="px-40 py-20">
            <h6 class="mb-10">{{ $t('subscriptions.feedback.title') }}</h6>
            <section class="mb-10 text-grey-600 text-sm">
              <p>{{ $t('subscriptions.feedback.body') }}</p>
            </section>
          </div>
        </div>
      </div>
      <Modal :active="supportModalOpen" closeOnBackDrop @close="closeModal">
        <ContactSupportModal @close="closeModal" />
      </Modal>
    </div>
  </RegistrationLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RegistrationLayout from '@components/layouts/RegistrationLayout'
import ContactSupportModal from '@components/common/ContactSupportModal'
import Modal from '@components/common/Modal'
import _map from 'lodash/map'
import { COMMUNICATION_METHOD_CODES } from '@platform-shared/constants'

export default {
  components: { RegistrationLayout, ContactSupportModal, Modal },
  props: {
    memberId: {
      type: String,
      default: '',
    },
    emailAddress: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    outreachType: {
      type: String,
      default: '',
    },
    outreachId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasError: !(this.memberId && this.emailAddress && this.token),
      preferencesSubmitted: false,
      commentSubmitted: false,
      comment: '',
      supportModalOpen: false,
    }
  },
  computed: {
    ...mapGetters('client', ['loginLogos']),
    clientLogos() {
      return _map(
        this.loginLogos,
        (logo) => `${this.$settings.S3_ROOT}/${logo}`
      )
    },
  },
  mounted() {
    if (!this.hasError) {
      this.unsubscribeFromEmailWithToken({
        communicationPreferences: [
          {
            communicationMethodCd: COMMUNICATION_METHOD_CODES.EMAIL,
            isAllowed: false,
            isPreferred: false,
          },
        ],
        token: this.token,
        memberId: this.memberId,
        outreachType: this.outreachType,
        outreachId: this.outreachId,
      }).catch(this.markRequestAsInvalid)
    }
  },
  methods: {
    ...mapActions('inbox', ['createSubscriptionCommentWithToken']),
    ...mapActions('member', ['unsubscribeFromEmailWithToken']),
    onSubmitComment() {
      this.createSubscriptionCommentWithToken({
        memberId: this.memberId,
        token: this.token,
        comment: this.comment,
      }).then(this.onCommentSubmitted)
    },
    onCommentSubmitted() {
      this.commentSubmitted = true
    },
    markRequestAsInvalid() {
      this.hasError = true
    },
    closeModal() {
      this.supportModalOpen = false
    },
  },
}
</script>
